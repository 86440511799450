import { appendVal, isNullUndefinedEmpty, spinnerHtml } from '../helpers/helpers';

import { svgInliner } from '../common/svgInliner';
import userStateManager from '../helpers/stateManager';

export const component = () => {
    //Handle 'automatic' mode of Contacts widget.
    //This is populated via profiledata and Contacts search APIs
    //Only contacts of type DFES and subtype LOCATION, from the users first BGU, will display

    const $autoContact = $('#regional-contacts');

    if ($autoContact.length && !$('body').hasClass('sfPageEditor')) {
        //First hide the content and place spinner while we hit API
        const $parentRow = $autoContact.parents('.row').first();
        $parentRow.hide();
        var $spinner = $(spinnerHtml);
        $parentRow.after($spinner);

        userStateManager.getUserProfileData((data) => {
            //Use the region from the user's first BGU, or fall back to nothing
            var defaultFilterRegion =
                !isNullUndefinedEmpty(data.ProfileInfo) && !isNullUndefinedEmpty(data.ProfileInfo.BGUs)
                    ? data.ProfileInfo.BGUs[0]['Region']
                    : null;

            if (data && data.apiProblemOccured) {
                console.error('An api error occured, so the automatic mode will not display');
            }

            if (!defaultFilterRegion || data.apiProblemOccured) {
                //catch for certain contacts or if an API problem occured (still want other contacts to display)
                $parentRow.show();
                $spinner.hide();
                return;
            }

            userStateManager.getContactsByRegion(defaultFilterRegion, (data) => {
                if (!isNullUndefinedEmpty(data)) {
                    const regionContacts = data.filter((item) => {
                        const isDfesType = item.contacttypes.some((item) => item === 'dfes');
                        const isLocationSubtype = item.contactsubtypes.some((item) => item === 'location');
                        return isDfesType && isLocationSubtype;
                    });
                    //Map the findings, in case there happens to be multiple
                    const regionContactsHtml = regionContacts.map((contact) => getContactHTML(contact)).join('');

                    $autoContact.replaceWith(regionContactsHtml);
                    svgInliner();
                    $spinner.hide();
                }
                $parentRow.show(); //show regardless
            });
        });
    }
};

export function getContactHTML(contact) {
    var contactList = [];
    var address = '';

    address = appendVal(address, contact.street, ', ');
    address = appendVal(address, contact.city, ' ');
    address = appendVal(address, contact.stateCode, ' ');
    address = appendVal(address, contact.zip, ' ');

    if (typeof contact.mobile !== 'undefined' && contact.mobile.length > 0) {
        contactList.push(`<li>Mobile: <a href="tel:${contact.mobile.replace(/ /g, '')}">${contact.mobile}</a></li>`);
    }

    if (typeof contact.phone !== 'undefined' && contact.phone.length > 0) {
        contactList.push(`<li>Phone: <a href="tel:${contact.phone.replace(/ /g, '')}">${contact.phone}</a></li>`);
    }

    if (typeof contact.phoneWork !== 'undefined' && contact.phoneWork.length > 0) {
        contactList.push(`<li>Phone (work): <a href="tel:${contact.phoneWork.replace(/ /g, '')}">${contact.phoneWork}</a></li>`);
    }

    if (typeof contact.fax !== 'undefined' && contact.fax.length > 0) {
        contactList.push(`<li>Fax: ${contact.fax}</li>`);
    }

    if (typeof contact.email !== 'undefined' && contact.email.length > 0) {
        contactList.push(`<li class="contact-email">Email: <a href="mailto:${contact.email}">${contact.email}</a></li>`);
    }

    if (typeof contact.web !== 'undefined' && contact.web.length > 0) {
        contactList.push(`<li>Web: <a href="${contact.web}" target="_blank">${contact.web}</a></li>`);
    }

    //Icon
    var iconUrl = '';
    var img = '';

    if (!isNullUndefinedEmpty(contact.iconUrl)) {
        iconUrl = contact.iconUrl;
        img = `<img class="image" src="${iconUrl}" alt="" />`;
    } else {
        var icon = 'icon-volunteer';

        if (contact.contactsubtypes.indexOf('person') > -1) {
            if (!isNullUndefinedEmpty(contact.serviceType)) {
                if (contact.serviceType == 'E') {
                    icon = 'icon-dfes-officer';
                }
            } else {
                if (contact.contactsubtypes.indexOf('staff') > -1) {
                    icon = 'icon-dfes-officer';
                }
            }
        } else if (contact.contactsubtypes.indexOf('bgu') > -1) {
            icon = 'icon-bgu';
        } else if (contact.contactsubtypes.indexOf('location') > -1) {
            icon = 'icon-building';
        }

        iconUrl = `/assets/static/icons/${icon}.svg`;
        img = `<img class="dfes-svg-icon svg" src="${iconUrl}" alt="" />`;
    }

    return `
        <div class="col-md-6">
            <div class="contact">
                <div class="contact__icon">
                  ${img}
                </div>
                <div class="contact__content">
                    <h2 class="contact__title">
                        ${contact.heading}
                    </h2>
                    <h4 class="contact__position">
                        ${contact.positionHeld}
                    </h4>
                    <ul class="contact__list">
                        <li>
                            ${address}
                        </li>
                        ${contactList.join('')}
                    </ul>
                </div>
            </div>
        </div>
    `;
}
